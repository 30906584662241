import IbkNoticeBox from '@/components/ibk/IbkNoticeBox.vue';

//---------------------
//분석
//---------------------
const analyzeRouter = [
    {
        path: "/analyze",
        name: "analyze",
        component: () => import("../views/analyze/AnalyzeHome.vue"),
    },
    {
        path: "/analyze/type",
        name: "analyze-type",
        component: () => import("../views/analyze/AnalyzeType.vue"),
    },
    {
        path: "/analyze/search",
        name: "analyze-search",
        component: () => import("../views/analyze/AnalyzeSearch.vue"),
    },
    {
        path: "/analyze/address",
        name: "analyze-address",
        component: () => import("../views/analyze/Analyze.vue"),
    },
    {
        path: "/analyze/price",
        name: "analyze-price",
        component: () => import("../views/analyze/AnalyzePrice.vue"),
    },
    {
        path: "/analyze/cash",
        name: "analyze-cash",
        component: () => import("../views/analyze/AnalyzeCash.vue"),
    },
    {
        path: "/analyze/firstrepay",
        name: "analyze-firstrepay",
        component: () => import("../views/analyze/AnalyzeFirstRepay.vue"),
    },
    {
        path: "/analyze/period",
        name: "analyze-period",
        component: () => import("../views/analyze/AnalyzePeriod.vue"),
    },
    {
        path: "/analyze/period-detail",
        name: "analyze-period-detail",
        component: () => import("../views/analyze/AnalyzePeriodDetail.vue"),
    },
    {
        path: "/analyze/section",
        name: "analyze-section",
        component: () => import("../views/analyze/AnalyzeSection.vue"),
    },
    {
        path: "/analyze/area",
        name: "analyze-area",
        component: () => import("../views/analyze/AnalyzeAddress.vue"),
    },
    {
        path: "/analyze/date",
        name: "analyze-date",
        component: () => import("../views/analyze/AnalyzeDate.vue"),
    },
    {
        path: "/analyze/date/living",
        name: "analyze-date-living",
        component: () => import("../views/analyze/AnalyzeDateLiving.vue"),
    },
    {
        path: "/analyze/faq",
        name: "analyze-faq",
        component: () => import("../views/analyze/AnalyzeFaq.vue"),
    },
    {
        path: "/analyze/faq-area",
        name: "analyze-faq-area",
        component: () => import("../views/analyze/AnalyzeFaqArea.vue"),
    },
    {
        path: "/analyze/extension",
        name: "analyze-faq-extension",
        component: () => import("../views/analyze/AnalyzeFaqExtension.vue"),
    },
    {
        path: "/analyze/faqwrite",
        name: "analyze-faqwrite",
        component: () => import("../views/analyze/AnalyzeFaqWrite.vue"),
    },
    {
        path: "/analyze/loan",
        name: "analyze-loan",
        component: () => import("../views/analyze/AnalyzeLoan.vue"),
    },    
    {
        path: "/analyze/amount",
        name: "analyze-amount",
        component: () => import("../views/analyze/AnalyzeAmount.vue"),
        beforeEnter: (to, from, next) => {
            if (localStorage.naezip_to_ac_se) {
                next();
            }
            else {
                next("/");
            }
        },
    },
    {
        path: "/analyze/share/amount",
        name: "analyze-share/amount",
        component: () => import("../views/amount/AnalyzeShareAmountView.vue"),
        beforeEnter: (to, from, next) => {
            if (localStorage.naezip_to_ac_se) {
                next();
            }
            else {
                next("/");
            }
        },
    },
    {
        path: "/analyze/share/complete",
        name: "analyze-share/complete",
        component: () => import("../views/amount/AnalyzeShareAmountCompleteView.vue"),
        beforeEnter: (to, from, next) => {
            if (localStorage.naezip_to_ac_se) {
                next();
            }
            else {
                next("/");
            }
        },
    },
    {
        path: "/analyze/address-search",
        name: "analyze-address-search",
        component: () => import("../components/address/Search.vue"),
        beforeEnter: (to, from, next) => {
            if (localStorage.naezip_to_ac_se) {
                next();
            }
            else {
                next('/');
            }
        },
    },
    {
        path: "/analyze/complete",
        name: "analyze-complete",
        component: () => import("../views/analyze/AnalyzeComplete.vue"),
        beforeEnter: (to, from, next) => {
            if (localStorage.naezip_to_ac_se) {
                next();
            }
            else {
                next("/");
            }
        },
    },
    {
        path: "/analyze/ticket-complete",
        name: "analyze-ticket-complete",
        component: () => import("../views/analyze/AnalyzeTicketComplete.vue"),
        beforeEnter: (to, from, next) => {
            if (localStorage.naezip_to_ac_se) {
                next();
            }
            else {
                next("/");
            }
        },
    },    
    {
        path: "/analyze/freebase",
        name: "analyze-freebase",
        component: () => import("../views/analyze/AnalyzeFreeBase.vue"),
    },
    {
        path: "/analyze/freelogin",
        name: "analyze-freelogin",
        component: () => import("../views/analyze/AnalyzeFreeLogin.vue"),
    },
    {
        path: "/analyze/freelogin-B",
        name: "analyze-freelogin-B",
        component: () => import("../views/a-b/AnalyzeFreeLogin-B.vue"),
    },
    {
        path: "/analyze/freelogin-C",
        name: "analyze-freelogin-C",
        component: () => import("../views/a-b/AnalyzeFreeLogin-C.vue"),
    },
    {
        path: "/analyze/report-login",
        name: "analyze-report-login",
        component: () => import("../views/analyze/AnalyzeReportLogin.vue"),
    },
    {
        path: "/analyze/free",
        name: "analyze-free",
        component: () => import("../views/analyze/AnalyzeFreeReport.vue"),
        beforeEnter: (to, from, next) => {
            if (localStorage.naezip_to_ac_se) {
                next();
            }
            else {
                next("/");
            }
        },
    },  
    {
        path: "/analyze/error",
        name: "analyze-error",
        component: () => import("../views/AnalyzeError.vue"),
    },
    {
        path: "/analyze/realtor-price",
        name: "analyze-faq-realtorprice",
        component: () => import("../views/analyze/AnalyzeFaqRealtorPrice.vue"),
    },
    {
        path: "/analyze/realtor-price-reason",
        name: "analyze-faq-realtorprice-reason",
        component: () => import("../views/analyze/AnalyzeFaqRealtorPriceReason.vue"),
    },
    {
        path: "/analyze/amount/review",
        name: "analyze-amount-review",
        component: () => import("../views/amount/AmountReviewView.vue"),
        beforeEnter: (to, from, next) => {
            if (localStorage.naezip_to_ac_se) {
                next();
            }
            else {
                next("/");
            }
        },
    },
    // IBK 전용 라우터(-ione)
    {
        path: "/analyze/kakao-login-ione",
        name: "analyze-kakao-login-ione",
        component: () => import("../views/ibk/IbkAnalyzeKakaoLogin.vue"),
    },
    {
        path: "/analyze/valid-campaign-ione",
        name: "analyze-valid-campaign-ione",
        component: () => import("../views/ibk/IbkAnalyzeValid.vue"),
    },
    {
        path: "/analyze/amount-ione",
        name: "analyze-amount-ione",
        component: () => import("../views/ibk/IbkAnalyzeAmount.vue"),
    },
    {
        path: "/analyze-ione",
        name: "analyze-ione",
        component: () => import("../views/ibk/IbkAnalyzeHome.vue"),
    },
    {
        path: "/analyze/type-ione",
        name: "analyze-type-ione",
        component: () => import("../views/analyze/AnalyzeType.vue"),
    },
    {
        path: "/analyze/search-ione",
        name: "analyze-search-ione",
        component: () => import("../views/analyze/AnalyzeSearch.vue"),
    },
    {
        path: "/analyze/address-ione",
        name: "analyze-address-ione",
        component: () => import("../views/analyze/Analyze.vue"),
    },
    {
        path: "/analyze/price-ione",
        name: "analyze-price-ione",
        component: () => import("../views/analyze/AnalyzePrice.vue"),
    },
    {
        path: "/analyze/cash-ione",
        name: "analyze-cash-ione",
        component: () => import("../views/analyze/AnalyzeCash.vue"),
    },
    {
        path: "/analyze/firstrepay-ione",
        name: "analyze-firstrepay-ione",
        component: () => import("../views/analyze/AnalyzeFirstRepay.vue"),
    },
    {
        path: "/analyze/period-ione",
        name: "analyze-period-ione",
        component: () => import("../views/analyze/AnalyzePeriod.vue"),
    },
    {
        path: "/analyze/period-detail-ione",
        name: "analyze-period-detail-ione",
        component: () => import("../views/analyze/AnalyzePeriodDetail.vue"),
    },
    {
        path: "/analyze/section-ione",
        name: "analyze-section-ione",
        component: () => import("../views/analyze/AnalyzeSection.vue"),
    },
    {
        path: "/analyze/area-ione",
        name: "analyze-area-ione",
        component: () => import("../views/analyze/AnalyzeAddress.vue"),
    },
    {
        path: "/analyze/date-ione",
        name: "analyze-date-ione",
        component: () => import("../views/analyze/AnalyzeDate.vue"),
    },
    {
        path: "/analyze/date/living-ione",
        name: "analyze-date-living-ione",
        component: () => import("../views/analyze/AnalyzeDateLiving.vue"),
    },
    {
        path: "/analyze/faq-ione",
        name: "analyze-faq-ione",
        component: () => import("../views/analyze/AnalyzeFaq.vue"),
    },
    {
        path: "/analyze/faq-area-ione",
        name: "analyze-faq-area-ione",
        component: () => import("../views/analyze/AnalyzeFaqArea.vue"),
    },
    {
        path: "/analyze/address-search-ione",
        name: "analyze-address-search-ione",
        component: () => import("../components/address/Search.vue"),
        beforeEnter: (to, from, next) => {
            if (localStorage.naezip_to_ac_se) {
                next();
            }
            else {
                next('/');
            }
        },
    },
    {
        path: "/analyze/complete-ione",
        name: "analyze-complete-ione",
        component: () => import("../views/analyze/AnalyzeComplete.vue"),
        beforeEnter: (to, from, next) => {
            if (localStorage.naezip_to_ac_se) {
                next();
            }
            else {
                next("/");
            }
        },
    },
    {
        path: "/analyze/report-login-ione",
        name: "analyze-report-login-ione",
        component: () => import("../views/analyze/AnalyzeReportLogin.vue"),
    },
];

export default analyzeRouter;
