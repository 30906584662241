import store from "../index.js";
import router from "../../router";

export const modules = {
  store,
};

export const state = {};

export const mutations = {};

export const actions = {
  /**
   * 구독 첫 결제 플로우 시작.
   * PG사 인증, 유저 검증 및 주문 번호 수신.
   * @returns {
   *   merchant_uid: string,
   *   mobile: string,
   *   user_name: string,
   *   email: string,
   *   user_no: string,
   *   AuthKey?: string, // payple
   *   return_url?: string, // payple
   *   store_id?: string, // portone
   *   channel_key?: string, // portone
   * }
   */
  async acSubscribeInfo({ dispatch }, { pg_name }) {
    // window.console.log("acSubscribeInfo params", params);
    try {
      let subscribeInfo = null;
      switch (pg_name) {
        case "payple": {
          subscribeInfo = await dispatch("paypleStore/acCertifiedInit", null, {
            root: true,
          });
          break;
        }
        case "kakaopay": {
          subscribeInfo = await dispatch(
            "portoneStore/acPortoneInfo",
            { pg_name: pg_name },
            { root: true }
          );
          break;
        }
      }
      // window.console.log("acSubscribeInfo res", subscribeInfo);
      return subscribeInfo;
    } catch (error) {
      // window.console.log("acSubscribeInfo error", error);
      return null;
    }
  },

  /**
   * 구독 결제 준비.
   * 비어있는 subscribe order, cart, map 생성.
   * @returns boolean
   */
  async acSubscribeInit(
    { dispatch, rootState },
    { pg_name, merchant_uid, price, is_yearly }
  ) {
    const { _items, reportStore } = rootState;
    const route = router.currentRoute;

    try {
      if (merchant_uid && pg_name) {
        const body = {
          price: price,
          pg_name: pg_name,
          merchant_uid: merchant_uid,
          yearly: is_yearly,
        };
        if (reportStore?._items?.report?.reportSeq) {
          body.report_seq = reportStore?._items?.report.reportSeq;
        } else {
          body.contract_type = _items.order.contract_type;
          body.deposit_amount = _items.order.deposit_amount;
          body.rental_amount = _items.order.rental_amount;
          body.address = _items.order.address;
          body.address_jibun = _items.order.address_jibun;
          body.address_si = _items.order.address_si;
          body.address_gu = _items.order.address_gu;
          body.address_dong = _items.order.address_dong;
          body.address_li = _items.order.address_li;
          body.building_name = _items.order.building_name;
          body.building_code = _items.order.building_code;
          body.building_number = _items.order.building_number;
          body.building_dong = _items.order.building_dong;
          body.building_ho = _items.order.building_ho;
          body.building_floor = _items.order.building_floor;
        }
        if (price === 0 && route.query.xmkrl) {
          body.code = route.query.xmkrl;
        }
        const init = await dispatch(
          "subscribeStore/acSubscribePaymentInit",
          body,
          { root: true }
        );
        return init;
      }
    } catch (err) {
      return null;
    }
  },
};

export const getters = {};

export default {
  namespaced: true,
  state,
  actions,
  mutations,
  getters,
};
