import store from "../../index.js";
import api from "../../../utils/api.js";
import CompareConstant from '../../../mixins/report/const/compareConstant.js';
import CompareAcConstant from '../../../mixins/report/const/compareAcConstant.js';


export const modules = {
    store,
};

export const state = {
    _items: [],
    _compareItems: [],
    _previewOrder: null
};

export const mutations = {  
    [CompareConstant.ADD_REPORT_LIST]: (state, payload) => {
        state._items = [];
        state._items = payload?.map(e => {
            return {
                ...e,
                isSelected: false
            }
        })
    },
    [CompareConstant.ADD_COMPARE_ITEMS]: (state, payload) => {
        state._compareItems = [];
        state._compareItems = payload;  
    },
    [CompareConstant.ADD_PREVIEW_ORDER]: (state, payload) => {
        state._previewOrder = null;
        state._previewOrder = payload;
    },
    [CompareConstant.ADD_RESET]: (state) => {
        state._items = [];
        state._compareItems = [];
        state._previewOrder = null;
    }
};

export const actions = {
    async acCompareReportList({ commit }) {
        const url = CompareAcConstant.GET_COMPARE_REPORT_LIST;        
        try {
            const res = await api.get(url);
            commit(CompareConstant.ADD_REPORT_LIST, res?.data?.data);
            return res?.data?.code === 0;
        } 
        catch(error) {
            return false;
        }
    },
    async acPreviewReportOrderAndCartSeq({ commit }, payload) {
        const url = CompareAcConstant.GET_PREVIEW_REPORT_ORDER_AND_CART_SEQ;        
        try {
            const res = await api.get(url, { params: payload });
            commit(CompareConstant.ADD_PREVIEW_ORDER, res?.data?.data);
            return res?.data?.code === 0;
        } 
        catch(error) {
            return false;
        }
    },
};

export const getters = {

};

export default {
    namespaced: true,
    state,
    actions,
    mutations,
    getters,
};