import store from "../../index.js";
import api from "../../../utils/api.js";
import OwnerHousePropertyAcConstant from "@/mixins/owner/const/ownerHousePropertyAcConstant.js";
import OwnerHousePropertyConstant from "@/mixins/owner/const/ownerHousePropertyConstant.js";

export const modules = {
  store,
};

export const state = {
  _items: {
    issued: false,
    purchased: false,
    state: "SEARCH_NO_DATA",
    owners: [],
    ownerNames: [],
    allProperties: [],
  },
  _contents: [],
  _contentsAll: [],
};

export const mutations = {
  [OwnerHousePropertyConstant.ADD_OWNER_HOUSE_PROPERTY_MORE]: (
    state,
    payload
  ) => {
    state._items = {
      issued: false,
      purchased: false,
      state: "SEARCH_NO_DATA",
      owners: [],
      ownerNames: [],
      allProperties: [],
      total: 0,
    };
    state._items.issued = payload?.issued || false;
    state._items.purchased = payload?.purchased || false;
    state._items.owners = payload?.owners || [];
    // 공유자 중 가진 집 개수가 많은 순으로 정렬.
    state._items.owners = state._items.owners.sort((a, b) => b.total - a.total);
    state._items.ownerNames =
      state._items.owners.map((owner) => owner.name.split("(")[0].trim()) || [];
    state._items.state = payload?.state || "SEARCH_NO_DATA";
    state._items.allProperties =
      state._items.owners?.reduce((acc, curr) => {
        return [...acc, ...curr.properties];
      }, []) || [];
    state._items.total = state._items.owners.reduce((acc, curr) => acc + curr.total, 0);
    // window.console.log("state._items :>> ", state._items);
  },
  [OwnerHousePropertyConstant.ADD_OWNER_HOUSE_PROPERTY_ANALYZE]: (
    state,
    payload
  ) => {
    // window.console.log("owner house analyze payload :>> ", payload);
    state._contents = [];
    state._contents =
      payload?.map((item) => ({ ...item, toggle: false })) || [];
    state._contentsAll =
      payload?.map((item) => ({ ...item, toggle: false })) || [];
    // window.console.log('state._contents: ', state._contents)
  },
  [OwnerHousePropertyConstant.ADD_OWNER_HOUSE_PROPERTY_ANALYZE_ALL]: (
    state,
    index
  ) => {
    console.log(state._contentsAll);
    if (index === 0) {
      state._contents =
        state._contentsAll
          ?.map((item) => {
            if (item.propertyYn) {
              if (!item.currentRisk?.length && !item.pastRisk?.length) {
                return { ...item, toggle: false };
              }
            } else {
              if (!item.pastRisk?.length) {
                return { ...item, toggle: false };
              }
            }
          })
          ?.filter((item) => item) || [];
    } else {
      state._contents =
        state._contentsAll
          ?.map((item) => {
            console.log("item", item);
            if (item.propertyYn) {
              if (item.currentRisk?.length || item.pastRisk?.length) {
                return { ...item, toggle: false };
              }
            } else {
              if (item.pastRisk?.length) {
                return { ...item, toggle: false };
              }
            }
          })
          ?.filter((item) => item) || [];
    }
    console.log(state._contents);
  },
};

export const actions = {
  async acOwnerHousePropertyMore({ commit }, params) {
    const url = OwnerHousePropertyAcConstant.GET_OWNER_HOUSE_PROPERTY_MORE;
    try {
      const res = await api.get(`${url}`, { params });
      // window.console.log("acOwnerHousePropertyMore res :>> ", res);
      if (res.data?.code === 0) {
        commit(
          OwnerHousePropertyConstant.ADD_OWNER_HOUSE_PROPERTY_MORE,
          res.data.data,
        );
      }
      return res.data?.code === 0 && res.data.data?.amount > 0;
    } catch (error) {
      return false;
    }
  },
  async acOwnerHousePropertyAnalyze({ commit }, params) {
    const url = OwnerHousePropertyAcConstant.GET_OWNER_HOUSE_PROPERTY_ANALYZE;
    try {
      const res = await api.get(`${url}`, { params });
      if (res.data?.code === 0) {
        commit(
          OwnerHousePropertyConstant.ADD_OWNER_HOUSE_PROPERTY_ANALYZE,
          res.data.data
        );
      }
      return res.data?.code === 0 && res.data.data?.length > 0;
    } catch (error) {
      return false;
    }
  },
  async acOwnerHousePropertySearch({ commit }, params) {
    const url = OwnerHousePropertyAcConstant.POST_OWNER_HOUSE_PROPERTY_SEARCH;
    try {
      const res = await api.post(`${url}`, { ...params });
      return res.data?.code === 0 && res.data.data?.length > 0;
    } catch (error) {
      return false;
    }
  },
  async acOwnerHousePropertyPolling({ commit }, params) {
    const url = OwnerHousePropertyAcConstant.GET_OWNER_HOUSE_PROPERTY_MORE;
    try {
      const res = await api.get(`${url}`, { params });
      if (res.data?.code === 0) {
        commit(
          OwnerHousePropertyConstant.ADD_OWNER_HOUSE_PROPERTY_MORE,
          res.data.data,
        );
      }
      return res.data?.code === 0 ? res?.data.data : null;
    } catch (error) {
      return null;
    }
  },
};

export const getters = {
  geCodeFail: (state) => {
    switch (state._error.code) {
    }
  },
};

export default {
  namespaced: true,
  state,
  actions,
  mutations,
  getters,
};
