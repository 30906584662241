const ibkApp = {};
const IONE_HOME_PATH = '/ione';

ibkApp.install = (Vue) => {
    const ufBackFunction = function() {
        const nzSource = sessionStorage.getItem('nz_source');
        if (!nzSource) {
            return this.$router.go(-1);
        }

        // /ione 으로 이동해야 하는 PATH
        const IONE_HOME_TARGET_PATH = [
            '/mypage-ione',
            '/analyze/type',
            '/analyze/type-ione',
            '/subscribe/choose',
            '/subscribe/choose-ione'
        ]

        // Confirm 후 /ione으로 이동해야 하는 PATH
        const IONE_HOME_CONFIRM_PATH = [
            '/analyze/amount',
            '/analyze/amount-ione',
            '/subscribe/amount',
            '/subscribe/amount-b',
            '/subscribe/amount-ione',
        ]

        if (this.$route.path === '/ione') {
            const confirmMsg = 'i-ONE뱅크 홈으로 이동하시겠습니까?';
            if (confirm(confirmMsg)) {
                $.nativeCall('exit', []);
            }
        } else if (IONE_HOME_TARGET_PATH.includes(this.$route.path)) {
            this.$router.push(IONE_HOME_PATH);
        } else if (IONE_HOME_CONFIRM_PATH.includes(this.$route.path)) {
            if (confirm('지금까지 입력한 정보가 초기화됩니다. 홈으로 이동하시겠습니까?')) {
                this.$router.push(IONE_HOME_PATH);
            }
        } else {
            this.$router.go(-1);
        }
    }

    Vue.prototype.uf_back = ufBackFunction;
    
    Vue.mixin({
        mounted() {
            if (this === this.$root) {
                window.uf_back = this.uf_back.bind(this);
            }
        }
    });
}

export default ibkApp;