import store from "../index.js";
import router from "../../router";
import api from "../../utils/api";
import Vue from "vue";
import { nameCheck, isMobile } from "../../utils/statusCheck";

export const modules = {
  store,
};

export const state = {};

export const mutations = {};

export const actions = {
  async acCertifiedInit() {
    try {
      const res = await api.get(`/api/v1/payple/info`);
      return res.data.data;
    } catch (error) {
      return null;
    }
  },
  async acCertified({ commit }, params) {
    try {
      const res = await api.post(`/api/v1/payple`, params);
      console.log("acCertified", res);
      return res.data.data;
    } catch (error) {
      console.log("acCertified error message", error);
      if (error.response.status === 401) {
        localStorage.clear();
        console.log(commit);
        return 401;
      } else {
        return null;
      }
    }
  },
  async acSubscribeInit({ commit }, params) {
    console.log("acSubscribeInit param", params);
    try {
      const res = await api.post(`/api/v1/subscribe/payment`, params);
      console.log("acSubscribeInit", res);
      return true;
    } catch (error) {
      console.log("acSubscribeInit error message", error.message);
      if (error.response.status === 401) {
        localStorage.clear();
        console.log(commit);
        return 401;
      } else {
        return false;
      }
    }
  },
  async acSubscribePaymentInit({ commit }, params) {
    try {
      const res = await api.post(`/api/v1/subscribe/payment`, params);
      return true;
    } catch (error) {
      if (error.response.status === 401) {
        localStorage.clear();
        console.log(commit);
        return 401;
      } else {
        alert(
          error.response.data.code === 6010
            ? "해당 주소지로 구독중인 알리미가 있습니다!"
            : error.response.data.message
        );
        return false;
      }
    }
  },
  async acSubscribeDate({ commit }, params) {
    console.log("acSubscribeDate param", params);
    try {
      const res = await api.post(`/api/v1/subscribe/payment/date`, params);
      console.log("acSubscribeDate", res);
      return true;
    } catch (error) {
      console.log("acSubscribeDate error message", error);
      if (error.response.status === 401) {
        localStorage.clear();
        console.log(commit);
      } else {
        return false;
      }
    }
  },
  async acFreeInitCheck({ commit }, params) {
    console.log("acSubscribeDate param", params);
    try {
      const res = await api.post(
        `/api/v1/payple/payment/complete-free`,
        params
      );
      return res.data.code === 0;
    } catch (error) {
      return false;
    }
  },

  /**
   * 페이플 결제창 띄워서 결제받기.
   */
  async acPayplePayment(
    { dispatch },
    {
      merchant_uid,
      user_no,
      email,
      mobile,
      user_name,
      price,
      is_yearly,
      AuthKey,
      return_url,
    }
  ) {
    // window.console.log("acPayplePayment params", {
    //   merchant_uid,
    //   user_no,
    //   email,
    //   mobile,
    //   user_name,
    //   price,
    //   is_yearly,
    // });

    const order_name = is_yearly ? "우리집알리미시작_연간" : "우리집알리미시작";

    const fnOrderResult = async (res) => {
      if (res && res.PCD_PAY_RST === "success") {
        if (res.PCD_PAY_WORK === "PAY") {
          if (
            Number(res.PCD_PAY_TOTAL) === price &&
            res.PCD_PAY_OID === merchant_uid
          ) {
            router.push("/subscribe/complete");
          } else {
            router.push("/analyze/error");
          }
        } else if (res.PCD_PAY_WORK === "AUTH") {
          const body = {
            player_id: res.PCD_PAYER_ID,
            cart_name: res.PCD_PAY_CARDNAME,
            cart_number: res.PCD_PAY_CARDNUM,
            uid: merchant_uid,
          };

          if (await dispatch("acFreeInitCheck", body)) {
            router.push("/subscribe/complete");
          } else {
            router.push("/analyze/error");
          }
        } else {
          router.push("/analyze/error");
        }
      } else if (res && res.PCD_PAY_RST === "close") {
        alert(`${res.PCD_PAY_MSG}`);
      } else {
        if (res) {
          alert(`${res.PCD_PAY_MSG}`);
          router.push("/analyze/error");
        } else {
          router.push("/analyze/error");
        }
      }
    };

    try {
      const obj = new Object();
      obj.PCD_PAY_TYPE = "card";
      obj.PCD_PAY_WORK = price === 0 ? "AUTH" : "PAY";
      obj.PCD_CARD_VER = "01";
      obj.PCD_PAY_OID = merchant_uid;
      obj.PCD_PAYER_NO = user_no.substring(0, 20);
      obj.PCD_PAYER_NAME = nameCheck(user_name) ? user_name : email;
      obj.PCD_PAYER_HP = mobile ? mobile.replace("+82 ", "0") : "";
      obj.PCD_PAYER_EMAIL = email;
      obj.PCD_PAY_GOODS = order_name;
      obj.PCD_PAY_TOTAL = price;
      obj.PCD_AUTH_KEY = AuthKey;
      obj.PCD_PAY_URL = return_url;

      // Web.
      if (!isMobile()) {
        obj.callbackFunction = fnOrderResult;
        obj.PCD_RST_URL = "/subscribe/complete";
      }
      // Mobile.
      else {
        obj.PCD_RST_URL =
          `${process.env.VUE_APP_HOST}` + `/api/v1/payple/payment/complete`;
      }
      // window.console.log("acPayplePayment obj", obj);
      Vue.prototype.$payple_pay(obj);
    } catch (error) {
      return false;
    }
  },

  /**
   * 페이플 카드 변경 결제창 띄워서 카드 등록 받기.
   */
  async acPaypleCardChange(
    { dispatch },
    {
      user_no,
      email,
      mobile,
      user_name,
      AuthKey,
      return_url,
      card_change_subscribe_seqs, // number[]
    }
  ) {
    // window.console.log("acPaypleCardChange params", {
    //   card_change_subscribe_seqs,
    // });

    const fnCardChangeResult = async (res) => {
      // window.console.log("fnCardChangeResult res", res);
      if (res && res.PCD_PAY_RST === "success") {
        if (res.PCD_PAY_WORK === "AUTH") {
          if (await dispatch(
            "subscribeStore/acSubscribeAnalyzeUpdateCards",
            {
              billing: res.PCD_PAYER_ID,
              uids: card_change_subscribe_seqs,
            },
            { root: true }
          )) {
            alert("수정이 완료되었습니다.");
            router.push("/");
          } else {
            alert("등록 실패");
            return false;
          }
        } else {
          router.push("/analyze/error");
        }
      } else {
        if (!res) {
          router.push("/analyze/error");
          return false;
        }

        console.log("resresres", res);
        alert(`${res.PCD_PAY_MSG}`);
        if (res.PCD_PAY_RST !== "close") router.push("/analyze/error");
      }
    };

    try {
      const obj = new Object();
      obj.PCD_PAY_TYPE = "card";
      obj.PCD_PAY_WORK = "AUTH";
      obj.PCD_CARD_VER = "01";
      obj.PCD_PAYER_NO = user_no.substring(0, 20);
      obj.PCD_PAYER_NAME = nameCheck(user_name) ? user_name : email;
      obj.PCD_PAYER_HP = mobile ? mobile.replace("+82 ", "0") : "";
      obj.PCD_PAYER_EMAIL = email;
      obj.PCD_AUTH_KEY = AuthKey;
      obj.PCD_PAY_URL = return_url;

      // Web.
      if (!isMobile()) {
        obj.callbackFunction = fnCardChangeResult;
      }
      // Mobile.
      else {
        obj.PCD_RST_URL =
          `${process.env.VUE_APP_HOST}` +
          `/api/v1/subscribe-payment/complete` +
          `?uids=${card_change_subscribe_seqs}`;
      }

      Vue.prototype.$payple_pay(obj);
    } catch (error) {
      return false;
    }
  },
};

export const getters = {};

export default {
  namespaced: true,
  state,
  actions,
  mutations,
  getters,
};
