<template>
    <div class="ibknoticebox" style="text-align: left;">        
        <div class=ibknotice_box_title @click="isToggle = !isToggle">
            <p>꼭 알아두세요!</p>
            <img src="@/assets/images/ibk/ico_accor_arrow.svg" alt="꼭 알아두세요!" v-show="isToggle">
            <img src="@/assets/images/ibk/ico_accor_arrow down.svg" alt="꼭 알아두세요!" v-show="!isToggle">
        </div>
        <ul class="ibknoticebox_list" v-show="isToggle">
            <li class="ibknoticebox_li">내집스캔은 i-ONE 자산관리 가입자에게 시범 운영기간동안 카카오로그인 계정으로 인당 리포트발급 1개, 알리미 신청 1개 무료이용이 가능합니다.</li>
            <li class="ibknoticebox_li">전세사기 리포트는 선착순 6천명, 등기부등본 변동알림은 선착순 4천명까지 이용료 결제없이 무상 제공합니다.</li>
            <li class="ibknoticebox_li">내집스캔은 아파트, 오피스텔, 다세대/연립주택만 이용 가능하며, 시세가 없는 경우가 있을 수 있습니다.</li>
            <li class="ibknoticebox_li">내집스캔의 전세사기 위험도를 진단하는 리포트 내용은 ㈜한국부동산데이터연구소가 제공 및 관리하고 있으며, 기업은행은 서비스 페이지에 연결하는 역할만 하며, 서비스의 제공과 그 책임은 위탁사인 ㈜한국부동산데이터연구소에 있습니다.</li>
            <li class="ibknoticebox_li">내집스캔의 리포트 및 서비스 전반에 대한 자세한 문의는 ㈜한국부동산데이터연구소 고객센터(070-8648-1890)와 카카오톡 채널 @내집스캔으로 문의하시기 바랍니다.</li>
            <li class="ibknoticebox_li">본 서비스는 출시 후 3개월만 진행되므로 조기종료 등에 따라 사전고지 없이 변경/중단될 수 있습니다.</li>
        </ul>
    </div> 
</template>

<script>
export default {
    name :"IbkNoticeBox",
    data() {
        return {
            isToggle: true
        }
    },
    methods: {
    }
}   
</script>

<style scoped>
.ibknoticebox p{
    margin:unset;
}
.ibknoticebox {
    padding: 0 20px;
    margin-bottom: 40px;
    margin-top:40px;
}
.ibknotice_box_title{
    display: flex;
    gap:8px;
    align-items: center;
    justify-content: start;
}
.ibknotice_box_title p{
    font-size:16px;
    font-weight: bold;
    line-height: 24px;
    color:#666;
}
.ibknotice_box_title img{
    width:10px;
    height:8px;
}
p {
    font-size: 14px;
    font-weight: 700;
}
.ibknoticebox_list {
    padding: 10px 0;    
}

.ibknoticebox_list .ibknoticebox_li {
    position: relative;
    padding-left: 11px;
    margin-top: 4px;
    color: #666;
    font-weight: normal;
    font-size: 14px;
    line-height: 1.4;
}

.ibknoticebox_list .ibknoticebox_li:before {
    content: '';
    display: block;
    position: absolute;
    left: 0;
    top: 7px;
    width: 3px;
    height: 3px;
    border-radius: 50%;
    background: #757575;
}
</style>