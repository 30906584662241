import Vue from "vue";
import Vuex from "vuex";
import axios from "axios";
import reportStore from './modules/report'
import subscribeStore from './modules/subscribe'
import paypleStore from './modules/payple';
import portoneStore from './modules/portone';
import subscribePaymentStore from './modules/subscribe-payment';
import orderStore from './modules/order';
import userStore from '././modules/user';
import theCheatStore from '././modules/thecheat';
import sciStore from '././modules/sci';
import loginStore from '././modules/login';
import addressStore from '././modules/address';
import mixpanelStore from "./modules/mixpanel";
import logStore from "./modules/log";
import contractStore from "./modules/contract";
import previewStore from "./modules/preview";
import reviewStore from "./modules/review";
import priceStore from "./modules/price";
import createPersistedState from "vuex-persistedstate";
import userInviteStore from '././modules/user/invite';
import subscribePointStore from '././modules/subscribe/point';
import simpleStore from '././modules/report/simple';
import orderDeterminedStore from '././modules/order/determined';
import orderTicketStore from './modules/order/ticket';
import api from '../utils/api';
import AnalyzeConstant from '@/mixins/analyze/const/analyzeConstant';
import couponTicketStore from './modules/coupon/ticket';
import loanGovStore from './modules/loan/gov';
import ownerHousePropertyStore from './modules/owner/house-property';
import campaignStore from './modules/campaign';
import experimentStore from './modules/user/experiment';
import compareStore from './modules/report/compare';

Vue.use(Vuex);

export default new Vuex.Store({
    plugins: [createPersistedState({
        paths: [
            "theCheatStore",
            "logStore", 
            "userStore", 
            "_items", 
            "sciStore", 
            "reportStore", 
            "subscribeStore", 
            "_state", 
            'contractStore',
            'orderDeterminedStore',
            'loanGovStore',
            'compareStore'
        ]
    })],
    state: {
        _state: {
            loadingBool: false,
            alertBool: false,
            loginState: false,
            toastBool: false,
            mypageSkeleton: false,
            payStatus: false,
            secessionState: false,
            realtorStatus: '',
            realtorState: false,
            checkAuthCodeState: false,
            addressCheckState: false,
            addressCheckCompleteState: false,
            addressUnsetState: false,
            addressDeterminedState:{
                address:null,
                building:null,
                addressStatus:null,
            },
            reportBottomPopup: {
                loan: false,
                detail: false,
                summary: false,
                senario: false,
                change: false
            },
            loanModalStatus: false,
            footerBool: false,
            ctaBool:false,
            ichBool: false,
        },
        _footer: {
            isAnalyze: false,
            isHome: true,
            isSubscribe: false,
            isMypage: false,
            isPreview: false,
            isFeed: false,
            isCommunity: false
        },
        _modal: {
            modalState: false,
            alertState: false,
            alertTitleState: false,
            alertContentNextState: false,
            confirmState: false,
            confirmCancelState:false,
            loginState: false,
            msg: "",
            title: "",
            event: "",
            event02: "",
            toastMsg: "",
            btn_cl:'취소',
            btn_co:'확인',
            btn_msg:''
        }, 
        _items: {
            order: [],
            juso: [],
            dong: [],
            floor: [],
            ho: [],
            address: [],
            simple: [],
            merchant: '',
            roadAddress: '',
            jibunAddress: '',
            simpleState: false,
            firstRepay: 0,
            notWithdrawReason: [],
            reasonWithdrawal: '',
            expectation_price: 0,
            expectation_reason: '',
            order_merchant: {},
            iros:null
        },
    },
    getters: {},
    mutations: {
        [AnalyzeConstant.ADD_COMPLETE_ADDRESS_UNSET]: (state, {address,building,addressStatus}) => {    
            state._state.addressDeterminedState.address = address;
            state._state.addressDeterminedState.building = building;
            state._state.addressDeterminedState.addressStatus = addressStatus;
        },
        [AnalyzeConstant.DELETE_COMPLETE_ADDRESS_UNSET]: (state) => {
            state._state.addressDeterminedState.address = null;
            state._state.addressDeterminedState.building = null;
            state._state.addressDeterminedState.addressStatus = null;
        },
        setIchBool(state, value){
            state._state.ichBool = value;
        },
        setIrosModifyAddress(state, address){
            state._items.iros = address;
        },
        setFooterBoolState(state, status) {
            state._state.footerBool = status;
        },     
        setCheckToastState(state, value) {
            if (value.type) {
                state._state.toastBool = true;
                state._modal.toastMsg = value.msg;
                setTimeout(() => {
                    state._state.toastBool = false
                    state._modal.toastMsg = ''
                }, 2000);
            } else {
                state._state.toastBool = false
                state._modal.toastMsg = ''
            }
        },
        setLoadingState(state, type) {
            state._state.loadingBool = type;
        },
        setOrderList(state, response) {
            for (let i = 0; i < response.value.length; i++) {
                response.value[i].createDate = response.value[i].createDate
                    .replace("T", " ")
                    .replace(".000Z", "");
            }
            state._items.order = response.value;
        },
        setPayStatus(state, status) {
            state._state.payStatus = status;
        },
        setSearchList(state, response) {
            state._items.juso = [];
            state._items.dong = [];
            state._items.floor = [];
            state._items.ho = [];
            state._items.address = [];
            state._items.simple = {};
            state._items.merchant = "";
            state._items.roadAddress = "";
            state._items.jibunAddress = "";
            state._items.simpleState = false;
            state._items.firstRepay = 0;

            state._items.juso = response.value;
        },
        setOrderReset(state) {
            state._items.order = [];
            state._items.juso = [];
            state._items.dong = [];
            state._items.floor = [];
            state._items.ho = [];
            state._items.address = [];
            state._items.simple = {};
            state._items.merchant = "";
            state._items.roadAddress = "";
            state._items.jibunAddress = "";
            state._items.firstRepay = 0;
            state._items.simpleState = false;            

            state._state.addressDeterminedState.addressStatus = null;
            state._state.addressDeterminedState.address = null;
            state._state.addressDeterminedState.building = null;
            // state._state.addressUnsetState = false;
        },
        setSearchDongList(state, response) {
            for (let i = 0; i < response.value.length; i++) {
                response.value[i].dongNm = !response.value[i].dongNm
                    ? "'동' 없음"
                    : response.value[i].dongNm;
            }

            state._items.dong = response.value;
        },
        setSearchFloorList(state, response) {
            for (let i = 0; i < response.value.length; i++) {
                response.value[i].floorNm = !response.value[i].floorNm
                    ? "'층' 없음"
                    : response.value[i].floorNm;
                response.value[i].hoNm = !response.value[i].hoNm
                    ? "'호' 없음"
                    : response.value[i].hoNm;
            }

            state._items.ho = response.value;
            let result = response.value.reduce(
                (ac, v) => (ac.includes(v.floorNm) ? ac : [...ac, v.floorNm]),
                []
            );
            state._items.floor = result;
        },
        setSearchAddress(state, response) {
            state._items.address = response.value;
        },
        setSearchOrder(state, response) {
            state._items.order = [];
            state._items.order = response.value;
        },
        setSearchOrderSingle(state, item) {
            state._items.order[item.obj] = item.value;
        },
        setSearchOrderMany(state, items) {
            const obj = Object.assign({}, state._items.order);
            state._items.order = {...obj, ...items}
        },
        setSearchContractType(state, response) {
            state._items.order = (!Array.isArray(state._items.order) || Object.keys(state._items.order).includes('price')) ? state._items.order : {};
            state._items.order.contract_type = response.value;
        },
        setSearchContractTypeSub(state, response) {
            state._items.order.contract_type = response.value;
        },
        setSearchMerchantUid(state, response) {
            state._items.order.merchant_uid = response.value;
        },
        setSearchDepositAmount(state, response) {
            state._items.order.deposit_amount = response.value;
        },
        setSearchDealAmount(state, response) {
            state._items.order.deal_amount = response.value;
        },
        setSearchRentalAmount(state, response) {
            state._items.order.rental_amount = response.value;
        },
        setSearchCashAmount(state, response) {
            state._items.simple.cash_amount = response.value;
        },
        setIndexSubscribeDate(state, response) {
            state._items.order.contract_date = response.value.contract_date;
            state._items.order.contract_date_living = response.value.contract_date_living;
        },
        setSearchFirstRepayAmount(state, response) {
            state._items.order.first_amount = response.value;
        },
        setSearchContractPeriod(state, response) {
            state._items.order = (!Array.isArray(state._items.order) || Object.keys(state._items.order).includes('price')) ? state._items.order : {};
            state._items.order.contract_period = response.value;
        },
        setSearchContractPeriodDetail(state, response) {
            state._items.order.contract_period_detail = response.value;
        },
        setSearchContractDate(state, response) {
            state._items.order.contract_date = response.value;
        },
        setSearchContractDateLiving(state, response) {
            state._items.order.contract_date_living = response.value;
        },
        setOrderInit(state, response) {
            state._items.merchant = response.value;
        },
        setSearchPrice(state, response) {
            state._items.order.price = response.value;
        },
        setSearchPgname(state, response) {
            state._items.order.pg_name = response.value;
        },
        setSearchOrderType(state, response) {
            state._items.order.order_type = response.value;
        },
        setSearchEmail(state, response) {
            state._items.order.email = response.value;
        },
        setSearchPrimerCoupon(state, response) {
            state._items.order.coupon = response.value;
        },
        setFaceBookCampaign(state) {
            if (localStorage.zs_rtl_id) {
                state._items.order.faq_write = state._items.order.faq_write && state._items.order.faq_write.length > 0
                    ? state._items.order.faq_write.concat(`,${localStorage.zs_rtl_id}`)
                    : localStorage.zs_rtl_id;
            }
        },
        setFreeReport(state, response) {
            state._items.simple.first_repay_yn = response.first_repay_yn;
            state._items.simple.first_repay_amount = (response.first_repay_amount / 10000).toLocaleString();
            state._items.simple.scam_town = response?.scam_town;
        },
        setMypageSkeleton(state) {
            state._state.mypageSkeleton = true;
        },
        setSearchOrderRoadAddress(state, response) {
            state._items.roadAddress = response.value;
        },
        setSearchOrderJibunAddress(state, response) {
            state._items.jibunAddress = response.value;
        },
        setOrderFaq(state, response) {
            if (state._items.order.faq?.length > 0) {
                if (!state._items.order.faq?.includes(response.value)) {
                    state._items.order.faq += `,${response.value}`
                }
            } else {
                state._items.order.faq = response.value;
            }


        },
        setOrderFaqReset(state, value) {
            if (value === 'faq') {
                let temp = ''
                if (state._items.order.faq?.includes('계약연장')) {
                    temp = '계약연장,';
                }
                if (state._items.order.faq?.includes('중도해지')) {
                    temp += '중도해지,';
                }
                if (state._items.order.faq?.includes('이미연장')) {
                    temp += '이미연장,';
                }
                temp = temp.substring(0, temp.length - 1);
                state._items.order.faq = '';
                state._items.order.faq = temp;
            } else {
                state._items.order.faq = '';
            }
        },
        setOrderLoan(state, response) {
            state._items.order.loan = response.value;
        },
        setOrderFaqExpectationPrice(state, response) {
            state._items.expectation_price = response;
        },
        setOrderFaqExpectationReason(state, response) {
            state._items.expectation_reason = response;
        },
        setFreeReportCommit(state, response) {
            state._items.simpleState = response.value.is_free;
            state._items.firstRepay = response.value.firest_repay;
        },
        setFooterState(state, value) {
            switch (value) {
                case '':
                    state._footer.isAnalyze = false;
                    state._footer.isHome = false;
                    state._footer.isSubscribe = false;
                    state._footer.isMypage = false;
                    state._footer.isPreview = false;
                    state._footer.isFeed = false;
                    state._footer.isCommunity = false;
                    break;

                case 'analyze':
                    state._footer.isAnalyze = true;
                    state._footer.isHome = false;
                    state._footer.isSubscribe = false;
                    state._footer.isMypage = false;
                    state._footer.isPreview = false;
                    state._footer.isFeed = false;
                    state._footer.isCommunity = false;
                    break;

                case 'main':
                    state._footer.isAnalyze = false;
                    state._footer.isHome = true;
                    state._footer.isSubscribe = false;
                    state._footer.isMypage = false;
                    state._footer.isPreview = false;
                    state._footer.isFeed = false;
                    state._footer.isCommunity = false;
                    break;

                case 'subscribe':
                    state._footer.isAnalyze = false;
                    state._footer.isHome = false;
                    state._footer.isSubscribe = true;
                    state._footer.isMypage = false;
                    state._footer.isPreview = false;
                    state._footer.isFeed = false;
                    state._footer.isCommunity = false;
                    break;

                case 'community':
                    state._footer.isAnalyze = false;
                    state._footer.isHome = false;
                    state._footer.isSubscribe = false;
                    state._footer.isMypage = false;
                    state._footer.isPreview = false;
                    state._footer.isFeed = false;
                    state._footer.isCommunity = true;
                    break;

                case 'mypage':
                    state._footer.isAnalyze = false;
                    state._footer.isHome = false;
                    state._footer.isSubscribe = false;
                    state._footer.isMypage = true;
                    state._footer.isPreview = false;
                    state._footer.isFeed = false;
                    state._footer.isCommunity = false;
                    break;

                case 'preview':
                    state._footer.isAnalyze = false;
                    state._footer.isHome = false;
                    state._footer.isSubscribe = false;
                    state._footer.isMypage = false;
                    state._footer.isPreview = true;
                    state._footer.isFeed = false;
                    state._footer.isCommunity = false;
                    break;

                case 'feed':
                    state._footer.isAnalyze = false;
                    state._footer.isHome = false;
                    state._footer.isSubscribe = false;
                    state._footer.isMypage = false;
                    state._footer.isPreview = false;
                    state._footer.isFeed = true;
                    state._footer.isCommunity = false;
                    break;
            }
        },
        setNotWithdrawReason(state, response) {
            state._items.notWithdrawReason = response;
        },
        setReasonWithdrawal(state, response) {
            state._items.reasonWithdrawal = response;
        },

        setSecessionState(state, response) {
            state._state.secessionState = response;
        },
        setRealtorStatus(state, response) {
            state._state.realtorStatus = response;
        },

        setRealtorState(state, response) {
            state._state.realtorState = response;
        },
        setCheckAuthCodeState(state, response) {
            state._state.checkAuthCodeState = response;
        },
        setReportBottomPopupLoan(state, response) {
            state._state.reportBottomPopup.loan = response
        },
        setReportBottomPopupDetail(state, response) {
            state._state.reportBottomPopup.detail = response
        },
        setReportBottomPopupSummary(state, response) {
            state._state.reportBottomPopup.summary = response
        },
        setReportBottomPopupSenario(state, response) {
            state._state.reportBottomPopup.senario = response
        },
        setReportBottomPopupChange(state, response) {
            state._state.reportBottomPopup.change = response
        },
        setAddressCheckState(state, response) {
            state._state.addressCheckState = response;
        },
        setAddressCheckCompleteState(state, response) {
            state._state.addressCheckCompleteState = response;
        },
        setCtaBtnState(state, payload){
            state._state.ctaBool = payload;
        },
        setLoanModalStatus(state, response) {
            state._state.loanModalStatus = response
        },
        setOrderMerchant(state, response) {
            state._items.order_merchant = response;
        },
        setOrderBuildingAddress(state, response) {
            state._items.order.building_code = response.building_code.length > 0 ? response.building_code : null;
            state._items.order.building_dong = response.building_dong.length > 0 ? response.building_dong : null;
            state._items.order.building_floor = response.building_floor.length > 0 ? response.building_floor : null;
            state._items.order.building_ho = response.building_ho.length > 0 ? response.building_ho : null;
        },
        setScamTown(state, response) {
            state._items.simple.scam_town = response?.scam_town;
        },
        setSeoAddressDefault(state, response){
            state._items.order.contract_date = '24';
            state._items.order.contract_period = '이사전';
            state._items.order.contract_period_detail = '계약예정';
            state._items.order.contract_type = response.contractType;
            state._items.order.deposit_amount = response.depositAmount;
            state._items.order.rental_amount = response.rentalAmount;
        }
    },
    actions: {
        async acReportList({state, commit}, params) {
            try {
                const response = await api.get(`/api/v1/report/admin`,
                    {
                        params: params,
                    }
                );
                commit("setOrderList", {value: response.data.data});
                return response;
            } catch (error) {
                state._state.loadingBool = false;
                console.log("error message", error.message);
                return error;
            } finally {
                commit("setLoadingState", false);
            }
        },
        async acRequestLogin({commit}, params) {
            const signup_source = window.sessionStorage.getItem('nz_source');
            params.signup_source = signup_source;
            try {
                const response = await api.get(`/api/v1/user/login/kakao`, {params: params});
                if (response.data.status === '탈퇴요청') {
                    commit('setSecessionState', true);
                }

                if (response.data.session) {
                    window.localStorage.setItem('naezip_to_ac_se', response.data.session);
                }
                return response.data;
            } catch (error) {
                return false;
            }
        },
        async acRequestRealtorLogin({commit}, params) {
            try {
                const response = await api.get(`/api/v1/realtor/login/kakao`, {params: params});

                if (response.data?.status === '탈퇴요청') {
                    commit('setSecessionState', true);
                }

                if (response.data?.session) {
                    window.localStorage.setItem('naezip_to_ac_se', response.data.session);
                }

                return response.data;
            } catch (error) {
                return false;
            }
        },
        acloadingState({commit}, type) {
            commit("setLoadingState", type);
        },
        async acSearchList({commit}, params) {
            const confmKey = process.env.VUE_APP_JUSO_SEARCH_KEY;
            const querys = {
                confmKey,
                ...params
            }
            try {
                const response = await axios.get(process.env.VUE_APP_JUSO_SEARCH_URL, {
                    params: querys
                });
                console.log("acSearchList response", response);
                commit("setSearchList", {value: response.data.results.juso});
                return response;
            } catch (error) {
                console.log(error.message);
            }
        },
        async acOrderReset({commit}) {
            commit("setOrderReset");
        },
        async acSearchDongist({commit}, params) {
            console.log("acSearchDongist params", params);
            // params.confmKey = process.env.VUE_APP_JUSO_SEARCH_DETAIL_KEY;
            const confmKey = process.env.VUE_APP_JUSO_SEARCH_DETAIL_KEY;
            const querys = {
                confmKey,
                ...params
            }
            try {
                const response = await axios.get(
                    process.env.VUE_APP_JUSO_SEARCH_DETAIL_URL,
                    {
                        params: querys
                    }
                );
                console.log("acSearchDongist response", response);
                commit("setSearchDongList", {value: response.data.results.juso});
                return response;
            } catch (error) {
                console.log(error.message);
            }
        },
        async acSearchFloorList({commit}, params) {
            console.log(params);
            // params.confmKey = process.env.VUE_APP_JUSO_SEARCH_DETAIL_KEY;
            const confmKey = process.env.VUE_APP_JUSO_SEARCH_DETAIL_KEY;
            const querys = {
                confmKey,
                ...params
            }
            try {
                commit("setLoadingState", true);
                const response = await axios.get(
                    process.env.VUE_APP_JUSO_SEARCH_DETAIL_URL,
                    {
                        params: querys
                    }
                );
                console.log("acSearchFloorList response", response);
                commit("setSearchFloorList", {value: response.data.results.juso});
                return response;
            } catch (error) {
                console.log(error.message);
            } finally {
                commit("setLoadingState", false);
            }
        },
        async acOrderInit({commit}, params) {
            try {
                const url = `/api/v1/order`;
                const response = await api.post(url, {...params}, {withCredentials: true});
                if (response?.data?.code === 0) {
                    if (params?.price > 0 || 
                        ((params?.coupon_code === 'lgoekqbwpm' || params?.coupon_code === 'jgkeziwomn') && params?.price === 0) || 
                        (['skdkwjqlwk','vmgkdlspzo','ieurksjqmn','optiekmnhg','zgsdmflqoi','ssfkjwjehr','gmbndbswqq','tyqnsploiu'].includes(params?.coupon_code) 
                        && params?.price === 0) || (params?.coupon_code?.includes('qkwjenzsnd') && params?.price === 0)
                    ) {
                        commit("setOrderInit", {value: response.data?.data?.merchant_uid});
                    }
                    return true;
                } else {
                    return false;
                }
            } catch (error) {
                if (error.response.status === 401) {
                    localStorage.clear();
                }
                commit('orderStore/setOrderError', error.response.data);
                return false;
            }
        },
        async acOrderInitOwnerProperty({ commit }, params) {
            try {
                const url = `/api/v1/order/owner-property`;
                const response = await api.post(url, { ...params }, { withCredentials: true });
                window.console.log('acOrderInitOwnerProperty response', response);
                if (response?.data?.code === 0) {
                    return response.data?.data?.merchant_uid;
                } else {
                    return false;
                }
            } catch (error) {
                console.log("acOrderInitOwnerProperty error message", error.message)
                return false;
            }
        },
        async acShareOrderInit({commit}, params) {
            try {
                // const url = `/api/v1/order/share`;
                const url = `/api/v1/order-cart-shares`;
                const response = await api.post(url, {...params}, {withCredentials: true});
                // commit("setOrderInit", { value: response.data.merchant_uid });
                return response.data?.data?.merchant_uid;
            } catch (error) {
                console.log("acOrderInit error message", error.message)
                return false;
            }
        },
        async acOrderGuaranteeInit({commit}, params) {
            try {
                const url = `/api/v1/order/guarantee`;
                const response = await api.post(url, {...params}, {withCredentials: true});
                if (response?.data?.code === 0) {
                    commit("setOrderInit", {value: response.data.data.merchant_uid});
                    return true;
                } else {
                    return false;
                }
            } catch (error) {
                if (error.response.status === 401) {
                    localStorage.clear();
                }
                return false;
            }
        },
        async acOrderCouponCommit({commit}, params) {
            try {
                const response = await api.post(`/api/v1/order-coupons/user`, params);
                commit('setOrderInit', {value: response.data?.data?.merchant_uid})
                return true;
            } catch (error) {
                if (error.response.status === 401) {
                    localStorage.clear();
                    console.log(commit)
                    return 401;
                } else {
                    return false;
                }
            }
        },
        async acOrderInitSubsribe({commit}, params) {
            console.log("acOrderInitSubsribe params", params);
            const url = `${process.env.VUE_APP_HOST}/api/v1/order/subscribe`;
            try {
                const response = await api.post(
                    url,
                    {...params}
                );
                console.log("status", response.status);
                commit("setOrderInit", {value: response.data.merchant_uid});
                return response.status;
            } catch (error) {
                if (error.response.status === 401) {
                    localStorage.clear();
                }
                return error.response.status;
            }
        },

        async acOrderComplete(params) {
            const url = `/api/v1/order/complete`;
            try {
                const response = await api.post(url, params);
                console.log("acOrderComplete response", response);
                return response;
            } catch (error) {
                if (error.response.status === 401) {
                    localStorage.clear();
                }
                return error.response.status;
            }
        },

        async acIsLogin() {
            try {
                const url = `/api/v1/user/auth/token`;
                const response = await api.get(url);

                if (response.status) {
                    return true;
                }
                else {
                    localStorage.removeItem('naezip_to_ac_se');
                    localStorage.removeItem('vuex');
                    return false;
                }
            } catch {
                localStorage.removeItem('naezip_to_ac_se');
                localStorage.removeItem('vuex');
                return false;
            }
        },

        async acUserEmail() {
            try {
                const url = `/api/v1/user/email`;
                const res = await api.get(url);
                if (res.data.code === 0) {
                    return res.data.data;
                } else {
                    return null;
                }
            } catch (err) {
                console.log('acUserEmail', err)
                return null;
            }
        },

        async acFreeReport({commit}, params) {
            const url = `/api/v1/report/simple`;
            try {
                const response = await api.post(url, params);
                commit("setFreeReport", response.data);
                return response;
            } catch (error) {
                if (error.response.status === 401) {
                    localStorage.clear();
                }
                return error.response.status;
            }
        },

        async acWithdrawal() {
            const url = `/api/v1/user/withdrawal`;
            try {
                await api.post(url, '');

                localStorage.clear();
                return true;
            } catch (error) {
                if (error.response.status === 401) {
                    localStorage.clear();
                }
                return false
            }
        },

        async acGetWithdrawal() {
            const url = `/api/v1/user/withdrawal`;
            try {
                const res = await api.get(url);

                if (res) {
                    return res.data.data;
                } else {
                    return false;
                }
            } catch (error) {
                return false
            }
        },

        async acWithdrawalComment(params) {
            const url = `/api/v1/user/withdrawal/comment`;
            try {
                await api.post(url, {comment: params.state._items.reasonWithdrawal});
                return true;
            } catch (error) {
                if (error.response.status === 401) {
                    localStorage.clear();
                }
                return false
            }
        },

        async acCancelWithdraw() {
            const url = `/api/v1/user/withdrawal`;
            try {
                const res = await api.patch(url);
                return res;
            } catch (error) {
                return null;
            }
        },

        async acOrderMerchant({commit}, params) {
            const url = `/api/v1/order/merchant?merchant=${params.merchant}&order_seq=${params.order_seq}`;

            try {
                const res = await api.get(url);
                if (res.data.data) {
                    commit('setOrderMerchant', res.data.data);
                }
                return true;
            } catch (error) {
                return false;
            }
        },

        async acGetOrderCheckCoupon(context, params) {
            const url = `/api/v1/order/coupon-welcome`;
            try {
                const res = await api.get(url, {...params});
                if (res.data.code === 0) {
                    return res.data.data;
                } else {
                    return false;
                }
            } catch (error) {
                console.log('error', error);
                return false;
            }
        },

        async acOrderCheckCoupon(context, params) {
            const url = `/api/v1/order/coupon-welcome`;
            try {
                const res = await api.post(url, {...params});

                return res.data;
            } catch (error) {
                console.log('error', error);
                return false;
            }
        },

        async acPatchOrderCheckCoupon(context, params) {
            const url = `/api/v1/order/coupon-welcome`;
            try {
                const res = await api.patch(url, {...params});

                return res.data;
            } catch (error) {
                console.log('error', error);
                return false;
            }
        },

        async acOrderRealtorCouponSearch(context, params) {
            const url = `/api/v1/order/coupon?counpon=${params}`;
            try {
                const res = await api.get(url);
                return res.data.code === 0 ? res.data.data : false;
            } catch (error) {
                return false;
            }
        },

        async acOrderRealtorCouponSave(context, params) {
            const url = `/api/v1/order/coupon`;
            try {
                const res = await api.post(url, {...params});
                return res.data;
            } catch (error) {
                console.log('error', error);
                return error.response.data;
            }
        },
        async acScamTown({commit}, params) {
            const url = `/api/v1/report/scam-town?address_si=${params.address_si}&address_gu=${params.address_gu}`;
            try {
                const res = await api.get(url);
                commit("setScamTown", res.data.data);
                return res.data;
            } catch (error) {
                return false;
            }
        },
  
        acSearchAddress({commit}, params) {
            commit("setSearchAddress", {value: params});
        },
        acSearchOrder({commit}, params) {
            commit("setSearchOrder", {value: params});
        },
        acSearchOrderSingle({commit}, params) {
            commit("setSearchOrderSingle", {obj: params.obj, value: params.value});
        },
        acSearchOrderMany({commit}, params) {
            commit("setSearchOrderMany", params);
        },
        acSearchContractType({commit}, params) {
            commit("setSearchContractType", {value: params});
        },
        acSearchContractTypeSub({commit}, params) {
            commit("setSearchContractTypeSub", {value: params});
        },
        acSearchDepositAmount({commit}, params) {
            commit("setSearchDepositAmount", {value: params});
        },
        acSearchRentalAmount({commit}, params) {
            commit("setSearchRentalAmount", {value: params});
        },
        acSearchDealAmount({commit}, params) {
            commit("setSearchDealAmount", {value: params});
        },
        acSearchCashAmount({commit}, params) {
            commit("setSearchCashAmount", {value: params})
        },
        acIndexSubscribeDate({commit}, params) {
            commit("setIndexSubscribeDate", {value: params});
        },
        acSearchFirstRepayAmount({commit}, params) {
            commit("setSearchFirstRepayAmount", {value: params});
        },
        acSearchContractPeriod({commit}, params) {
            commit("setSearchContractPeriod", {value: params});
        },
        acSearchContractPeriodDetail({commit}, params) {
            commit("setSearchContractPeriodDetail", {value: params});
        },
        acSearchContractDate({commit}, params) {
            commit("setSearchContractDate", {value: params});
        },
        acSearchContractDateLiving({commit}, params) {
            commit("setSearchContractDateLiving", {value: params});
        },
        acSearchPrice({commit}, params) {
            commit("setSearchPrice", {value: params});
        },
        acSearchPgname({commit}, params) {
            commit("setSearchPgname", {value: params});
        },
        acSearchOrderType({commit}, params) {
            commit("setSearchOrderType", {value: params});
        },
        acSearchEmail({commit}, params) {
            commit("setSearchEmail", {value: params});
        },
        acSearchPrimerCoupon({commit}, params) {
            commit("setSearchPrimerCoupon", {value: params});
        },
        acFaceBookCampaign({commit}) {
            commit("setFaceBookCampaign");
        },
        acMypageSkeleton({commit}) {
            commit("setMypageSkeleton");
        },
        acSearchOrderRoadAddress({commit}, params) {
            commit("setSearchOrderRoadAddress", {value: params});
        },
        acSearchOrderJibunAddress({commit}, params) {
            commit("setSearchOrderJibunAddress", {value: params});
        },
        acOrderFaq({commit}, params) {
            commit("setOrderFaq", {value: params});
        },
        acOrderFaqReset({commit}, value) {
            commit("setOrderFaqReset", value);
        },
        acOrderLoan({commit}, params) {
            commit("setOrderLoan", {value: params});
        },
        acFreeReportCommit({commit}, params) {
            commit("setFreeReportCommit", {value: params});
        },
        acFooterState({commit}, value) {
            commit("setFooterState", value);
        },
        acCheckToastState({commit}, value) {
            commit("setCheckToastState", {type: value.type, msg: value.msg});
        },
        acSearchMerchantUid({commit}, param) {
            commit('setSearchMerchantUid', {value: param})
        },
        acPayStatus({commit}, value) {
            commit('setPayStatus', value)
        },
        acNotWithdrawReason({commit}, value) {
            commit('setNotWithdrawReason', value);
        },

        acReasonWithdrawal({commit}, value) {
            commit('setReasonWithdrawal', value);
        },

        acRealtorState({commit}, value) {
            commit('setRealtorState', value);
        },

        acCheckAuthCodeState({commit}, value) {
            commit('setCheckAuthCodeState', value);
        },

        acOrderFaqExpectationPrice({commit}, value) {
            commit('setOrderFaqExpectationPrice', value);
        },

        acOrderFaqExpectationReason({commit}, value) {
            console.log('value', value)
            commit("setOrderFaqExpectationReason", value)
        },
        acAddressCheckState({commit}, value) {
            commit('setAddressCheckState', value);
        },
        acAddressCheckCompleteState({commit}, value) {
            commit('setAddressCheckCompleteState', value);
        },
        acAnalyzeCompleteAddressUnset({commit}, value) {
            // commit('setAnalyzeCompleteAddressUnset', value);
        },
        acLoanModalStatus({commit}, value) {
            commit('setLoanModalStatus', value);
        },
        acSearchBuildingAddress({commit}, value) {
            commit('setOrderBuildingAddress', value);
        },
        acCtaBtnState({commit}, state) {
            commit('setCtaBtnState', state);
        },
        acIrosModifyAddress({commit}, address){
            commit('setIrosModifyAddress', address);
        },        
        acReportBottomPopup({commit}, value) {
            if (value === 'reset') {
                commit("setReportBottomPopupLoan", false);
                commit("setReportBottomPopupSummary", false);
                commit("setReportBottomPopupDetail", false);
                commit("setReportBottomPopupSenario", false);
                commit("setReportBottomPopupChange", false);
            } else if (value.includes('loan')) {
                console.log('value', value);
                if (value.includes('in')) {
                    commit("setReportBottomPopupLoan", true);
                } else {
                    commit("setReportBottomPopupLoan", false);
                }
            } else if (value.includes('summary')) {
                console.log('value', value);
                if (value.includes('in')) {
                    commit("setReportBottomPopupSummary", true);
                } else {
                    commit("setReportBottomPopupSummary", false);
                }
            } else if (value.includes('detail')) {
                console.log('value', value);
                if (value.includes('in')) {
                    commit("setReportBottomPopupDetail", true);
                } else {
                    commit("setReportBottomPopupDetail", false);
                }
            } else if (value.includes('senario')) {
                console.log('value', value);
                if (value.includes('in')) {
                    commit("setReportBottomPopupSenario", true);
                } else {
                    commit("setReportBottomPopupSenario", false);
                }
            } else if (value.includes('change')) {
                console.log('value', value);
                if (value.includes('in')) {
                    commit("setReportBottomPopupChange", true);
                } else {
                    commit("setReportBottomPopupChange", false);
                }
            }
        }
    },
    modules: {
        reportStore,
        subscribeStore,
        paypleStore,
        portoneStore,
        orderStore,
        userStore,
        theCheatStore,
        loginStore,
        sciStore,
        addressStore,
        mixpanelStore,
        logStore,
        contractStore,
        previewStore,
        priceStore,
        reviewStore,
        userInviteStore,
        subscribePointStore,
        simpleStore,
        orderTicketStore,
        orderDeterminedStore,
        couponTicketStore,
        loanGovStore,
        ownerHousePropertyStore,
        campaignStore,
        experimentStore,
        subscribePaymentStore,
        compareStore,
    },
});
