
//---------------------
//a-b테스트
//---------------------
const abRouter = [
    {
        path: "/a-b/dateconfirm",
        name: "ab-dateconfirm",
        component: () => import("../views/a-b/DateConfirmView.vue"),
    },
    {
        path: "/a-b/prevention",
        name: "ab-prevention",
        component: () => import("../views/a-b/PreventionView.vue"),
    },    
    {
        path: "/reels-kre",
        name: "reels-kre",
        component: () => import("../views/a-b/ReelsNotionLinkView.vue"),
    },          

    //전세대출
    {
        path: "/analyze/loan-amount",
        name: "loan-amount",
        component: () => import("../views/a-b/AnalyzeLoanAmountView.vue"),
        beforeEnter: (to, from, next) => {
            if (localStorage.naezip_to_ac_se) {
                next();
            }
            else {
                next("/");
            }
        },
    }, 

    // 전세대출
    {
        path: "/loan/gov/possible/analyze",
        name: "loan-gov-possible-analyze",
        component: () => import("../views/a-b/LoanPossibleGovAnalyzeView-A.vue"),
        beforeEnter: (to, from, next) => {
            if (localStorage.naezip_to_ac_se) {
                next();
            }
            else {
                next("/");
            }
        },
    },
    {
        path: "/loan/gov/possible/analyze-b",
        name: "loan-gov-possible-analyze-b",
        component: () => import("../views/a-b/LoanPossibleGovAnalyzeView-B.vue"),
        beforeEnter: (to, from, next) => {
            if (localStorage.naezip_to_ac_se) {
                next();
            }
            else {
                next("/");
            }
        },
    },

    // 구독 연간 이용권
    {
        path: "/subscribe/amount-b",
        name: "subscribe-amount-b",
        component: () => import("../views/a-b/SubscribeAmount-B.vue"),
    },

    {
        path: "/subscribe/amount-test",
        name: "subscribe-amount-test",
        component: () => import("../views/a-b/SubscribeAmount-Test.vue"),
    },
];

export default abRouter;
