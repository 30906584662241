import store from "../index.js";
import api from "../../utils/api";
import router from "@/router/index.js";
import PortOne from "@portone/browser-sdk/v2";

export const modules = {
  store,
};

export const state = {};

export const mutations = {};

export const actions = {
  /**
   * 포트원 결제창 띄우기 위한 정보 획득.
   */
  async acPortoneInfo(context, { pg_name }) {
    try {
      const res = await api.get(`/api/v1/portone/info?pg_name=${pg_name}`);
      return res.data.data;
    } catch (error) {
      return null;
    }
  },

  /**
   * 포트원에서 발급 받은 빌링키 서버에 전송.
   */
  async acPortoneBillingKey(
    context,
    { billing_key, merchant_uid, order_name, pg_name }
  ) {
    // window.console.log("acPortoneBillingKey", params);
    try {
      const res = await api.post(`/api/v1/portone/payment/billingkey`, {
        billing_key,
        pg_name,
        merchant_uid,
        order_name,
      });
      // window.console.log("acPortoneBillingKey res", res);
      return res.status === 200;
      // router.push('/subscribe/complete');
    } catch (error) {
      // window.console.log("acPortoneBillingKey error", error);
      return false;
      // router.push('/analyze/error');
    }
  },

  /**
   * 포트원에서 발급 받은 빌링키 카드변경 용도로 서버에 전송.
   */
  async acPortoneBillingKeyUpdate(context, { billing_key, uids, pg_name }) {
    try {
      const res = await api.patch(`/api/v1/portone/card-change`, {
        billing_key,
        uids,
        pg_name,
      });
      if (res.data?.code === 0) {
        return true;
      } else {
        return false;
      }
    } catch (error) {
      return false;
    }
  },

  /**
   * 포트원에서 빌링키 발급 및 서버에 전송.
   */
  async acPortonePayment(
    { dispatch },
    { pg_name, is_yearly, merchant_uid, store_id, channel_key, user_no }
  ) {
    const order_name = is_yearly ? "우리집알리미시작_연간" : "우리집알리미시작";
    // window.console.log("redirectUrl", `${process.env.VUE_APP_HOST}/api/v1/portone/payment/complete/${user_no}/${merchant_uid}/${order_name}/${pg_name}`);

    // 빌링키 발급
    const billingKeyRes = await PortOne.requestIssueBillingKey({
      storeId: store_id,
      channelKey: channel_key,
      billingKeyMethod: "EASY_PAY",
      issueName: "우리집알리미 정기결제", // 빌링키 발급 시 결제창에 표시되는 제목
      redirectUrl:
        `${process.env.VUE_APP_HOST}` +
        `/api/v1/portone/payment/complete` +
        `/${user_no}/${merchant_uid}` +
        `/${encodeURIComponent(order_name)}` +
        `/${pg_name}`,
    });
    if (billingKeyRes.message === "사용자가 프로세스를 중단하였습니다.") {
      alert("결제를 종료하였습니다.");
    } else {
      const paymentSuccess = await dispatch("acPortoneBillingKey", {
        billing_key: billingKeyRes.billingKey,
        merchant_uid: merchant_uid,
        order_name: is_yearly ? "우리집알리미시작_연간" : "우리집알리미시작",
        pg_name: pg_name,
      });
      // window.console.log("PortOne paymentSuccess", paymentSuccess);
      if (paymentSuccess) {
        router.push("/subscribe/complete");
      } else {
        router.push("/analyze/error");
      }
    }
  },

  async acPortoneCardChange(
    { dispatch },
    {
      pg_name,
      store_id,
      channel_key,
      user_no,
      card_change_subscribe_seqs, // number[]
    }
  ) {
    // 빌링키 발급
    const billingKeyRes = await PortOne.requestIssueBillingKey({
      storeId: store_id,
      channelKey: channel_key,
      billingKeyMethod: "EASY_PAY",
      issueName: "우리집알리미 정기결제", // 빌링키 발급 시 결제창에 표시되는 제목
      redirectUrl:
        `${process.env.VUE_APP_HOST}` +
        `/api/v1/portone/card-change/complete` +
        `/${user_no}` +
        `/${pg_name}` +
        `/${card_change_subscribe_seqs.join(",")}`,
    });
    if (billingKeyRes.message === "사용자가 프로세스를 중단하였습니다.") {
      alert("결제를 종료하였습니다.");
    } else {
      const cardChangeSuccess = await dispatch("acPortoneBillingKeyUpdate", {
        pg_name: pg_name,
        billing_key: billingKeyRes.billingKey,
        uids: card_change_subscribe_seqs,
      });
      // window.console.log("PortOne cardChangeSuccess", cardChangeSuccess);
      if (cardChangeSuccess) {
        alert("수정이 완료되었습니다.");
        router.push("/");
      } else {
        alert("등록 실패");
        // router.push("/analyze/error");
        return false;
      }
    }
  },
};

export const getters = {};

export default {
  namespaced: true,
  state,
  actions,
  mutations,
  getters,
};
