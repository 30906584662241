// src/utils/storage.js
export const getValidCampaign = () => {
    try {
        const validCampaign = sessionStorage.getItem('valid_campaign');
        return validCampaign ? String(validCampaign) : false;
    } catch (error) {
        console.error('Error getting valid campaign from sessionStorage:', error);
        return false;
    }
}

export const setValidCampaign = (campaign) => {
    try {
        sessionStorage.setItem('valid_campaign', campaign);
    } catch (error) {
        console.error('Error setting valid campaign in sessionStorage:', error);
    }
}

export const removeNzToken = () => {
    try {
        sessionStorage.removeItem('nz_t');
    } catch (error) {
        console.error('Error removing nz_t in sessionStorage:', error);
    }
}

export const removeValidCampaignAndNzToken = () => {
    try {
        sessionStorage.removeItem('valid_campaign');
        sessionStorage.removeItem('nz_t');
    } catch (error) {
        console.error('Error removing valid campaign and nz_t in sessionStorage:', error);
    }
}

export const removeValidCampaign = () => {
    try {
        sessionStorage.removeItem('valid_campaign');
    } catch (error) {
        console.error('Error removing valid campaign in sessionStorage:', error);
    }
}

export const removeNzSource = () => {
    try {
        sessionStorage.removeItem('nz_source');
    } catch (error) {
        console.error('Error removing nz_source in sessionStorage:', error);
    }
}

export const setValidCampaignAndNzSource = (campaign, nzSource) => {
    try {
        sessionStorage.setItem('valid_campaign', campaign);
        sessionStorage.setItem('nz_source', nzSource);
    } catch (error) {
        console.error('Error setting valid campaign and nz_source in sessionStorage:', error);
    }
}

export const setNzToken = (nzToken) => {
    try {
        sessionStorage.setItem('nz_t', nzToken);
    } catch (error) {
        console.error('Error setting nz_t in sessionStorage:', error);
    }
}

export const getNzSource = () => {
    try {
        const nzSource = sessionStorage.getItem('nz_source');
        return nzSource ? String(nzSource) : false;
    } catch (error) {
        console.error('Error getting nz_source from sessionStorage:', error);
        return false;
    }
}

export const setSessionNzSource = (nzSource) => {
    try {
        sessionStorage.setItem('nz_source', nzSource);
    } catch (error) {
        console.error('Error setting nz_source in sessionStorage:', error);
    }
}

export const getNzToken = () => {
    try {
        const nzToken = sessionStorage.getItem('nz_t');
        return nzToken ? String(nzToken) : false;
    } catch (error) {
        console.error('Error getting nz_t from sessionStorage:', error);
        return false;
    }
}

export const setNzApp = (nzApp) => {
    try {
        sessionStorage.setItem('nz_app', nzApp);
    } catch (error) {
        console.error('Error setting nz_app in sessionStorage:', error);
    }
}

export const getNzApp = () => {
    try {
        const nzApp = sessionStorage.getItem('nz_app');
        return nzApp ? String(nzApp) : false;
    } catch (error) {
        console.error('Error getting nz_app from sessionStorage:', error);
        return false;
    }
}