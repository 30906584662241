export default {
    install(Vue, options = {}) {
      // 결제 서비스 객체 생성
      const PaymentService = {
        impId: options.impId || "imp05687488",
        isInitialized: false,
        status: null,
        error: null,
        isProcessing: false,
        buyerTel: '010-2810-0647',

        // IMP 초기화
        initialize() {
          if (window.IMP && !this.isInitialized) {
            window.IMP.init(this.impId);
            this.isInitialized = true;
            return true;
          } else if (this.isInitialized) {
            return true;
          } else {
            console.error("IMP not loaded");
            return false;
          }
        },
        
        // 결제 요청
        requestPayment(paymentData) {
          if (!this.initialize()) return Promise.reject(new Error("Payment module not initialized"));
          
          this.isProcessing = true;
          this.status = null;
          this.error = null;
          
          return new Promise((resolve, reject) => {
            window.IMP.request_pay(
              {
                pg: 'html5_inicis',
                pay_method: 'card',
                merchant_uid: paymentData.merchantUid,
                name: paymentData.name,
                amount: paymentData.amount,
                buyer_email: paymentData.buyerEmail,
                buyer_name: paymentData.buyerName,
                buyer_tel: this.buyerTel,
                m_redirect_url: paymentData.mRedirectUrl,
              },
              (response) => {
                this.isProcessing = false;                
                if (response.success) {
                  this.status = 'success';
                  resolve({
                    success: true,
                    ...response
                  });
                } else {
                  this.status = 'fail';
                  this.error = response.error_msg;
                  reject({
                    success: false,
                    ...response
                  });
                }
              }
            );
          });
        },    
      };
      Vue.prototype.$inicisPayment = PaymentService;
    }
  };