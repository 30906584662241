<template>
    <div id="app">
        <SideArticleReview></SideArticleReview>
        <router-view class="" :key="$route.fullPath"/>
        <FooterNavigation v-show="_state.footerBool"></FooterNavigation>
        <LoadingOverlay v-if="_state.loadingBool"/>      
        <transition name="slide-fade">
            <ToasMessage v-if="_state.toastBool" />
        </transition>
        <Alert v-if="_modal.modalState" />
    </div>
</template>

<script src="https://cpay.payple.kr/js/cpay.payple.1.0.1.js"></script>
<script>
  import LoadingOverlay from '@/components/common/Loading.vue'
  import ToasMessage from '@/components/common/ToastMessage.vue'
  import Alert from '@/components/common/Alert.vue'
  import FooterNavigation from '@/components/footer/navigation.vue'
  import SideArticleReview from '@/components/side-article-landing.vue'
  import { mapState, mapMutations } from 'vuex';
  import { mapActions } from 'vuex';
  import ChannelService from '@/mixins/ChannelService'
  
  export default {
      name: 'App',
      watch: {
        '$route.path': {
          immediate: true,
          handler(newPath) {
            const element = document.getElementById('conveythis-wrapper');
            const element2 = document.querySelector('.conveythis-no-translate');
            const element3 = document.querySelector('.conveythis-widget-main');
            if (element || element2 || element3) {
              element.style.display = (newPath === '/' || newPath === '/analyze') ? 'block' : 'none';
            }
          }
        }
      },      
      components: {
          LoadingOverlay,
          ToasMessage,
          Alert,
          FooterNavigation,
          SideArticleReview,
      },
      created() {

      },
      data() {
        return {
          isThrottle: false,
          touchHandler: null,
        }
      },
      mounted() {
        this._state.footerBool = true;
        this.acCheckToastState({type: false, msg: ''});
        this.acInitializeNzSource();
        ChannelService.boot();
        ChannelService.onShowMessenger(this.fnChannelTalk);
        
        // 터치 이벤트 리스너 추가
        if(this._nz_source === 'ione'){
          this.fnSetupTouchRefreshHandler();
        }
      },
      beforeDestroy() {
        this.fnCleanupTouchRefreshHandler();
      },
      computed: {
          ...mapState(['_state', '_modal']),
          ...mapState('userStore', ['_channelTalk']),
          ...mapMutations(['setFooterBoolState']),
          ...mapState('campaignStore', ['_nz_source']),
      },    
      methods:{
          ...mapActions([
              'acCheckToastState'
          ]),
          ...mapActions('userStore', ['acChannelTalk']),
          ...mapActions('campaignStore', ['acInitializeNzSource']),
          async fnChannelTalk(){
              const user = await this.acChannelTalk();
              if(user){
                  const { email, mobile, order_count, report_status} = this._channelTalk;
                  ChannelService.updateUserObject(email, mobile, order_count, report_status);
              }
          },
          fnIbkTokenRefresh(){
            if(this._nz_source === 'ione'){     
              $.nativeCall('refreshSession',[]);            
            }
          },
          fnSetupTouchRefreshHandler() {
            this.fnCleanupTouchRefreshHandler();
            this.touchHandler = () => {
              if (!this.isThrottle) {
                this.isThrottle = true;
                this.fnIbkTokenRefresh();
                setTimeout(() => {
                  this.isThrottle = false;
                }, 1000);
              }
            };
            document.addEventListener('click', this.touchHandler);
          },
          fnCleanupTouchRefreshHandler() {
            // 이벤트 리스너 제거
            if (this.touchHandler) {
              document.removeEventListener('click', this.touchHandler);
              this.touchHandler = null;
            }
          }
      }
  }
</script>
<style>
.fade-enter {
  opacity: 0;
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s ease-out;
}

.fade-leave-to {
  opacity: 0;
}

@media screen and (max-width: 1080px){
  .slide-fade-enter {
    transform: translateX(10px);
    opacity: 0;
  }

  .slide-fade-enter-active,
  .slide-fade-leave-active {
    transition: all 0.1s ease;
  }

  .slide-fade-leave-to {
    transform: translateX(-10px);
    opacity: 0;
  }
}

</style>