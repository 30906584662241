

const subscribeRouter = [
    {
        path: "/subscribe",
        name: "subscribe",
        component: () => import("../views/subscribe/MainView.vue"),
    },
    {
        path: "/subscribe-a",
        name: "subscribe-a",
        component: () => import("../views/subscribe/MainView.vue"),
    },
    {
        path: "/subscribe/choose",
        name: "subscribe-choose",
        component: () => import("../views/subscribe/SubscribeChoose.vue"),
    },
    {
        path: "/subscribe/address",
        name: "subscribe-address",
        component: () => import("../views/subscribe/SubscribeAddress.vue"),
    },

    {
        path: "/subscribe/search",
        name: "subscribe-search",
        component: () => import("../views/subscribe/SubscribeSearch.vue"),
    },
    {
        path: "/subscribe/type",
        name: "subscribe-type",
        component: () => import("../views/subscribe/SubscribeType.vue"),
    },
    {
        path: "/subscribe/price",
        name: "subscribe-price",
        component: () => import("../views/subscribe/SubscribePrice.vue"),
    },
    {
        path: "/subscribe/freelogin",
        name: "subscribe-freelogin",
        component: () => import("../views/subscribe/SubscribeFreeLogin.vue"),
    },
    {
        path: "/subscribe/amount",
        name: "subscribe-amount",
        component: () => import("../views/subscribe/SubscribeAmount.vue"),
    },
    {
        path: "/subscribe/init",
        name: "subscribe-init",
        component: () => import("../views/subscribe/InitView.vue"),
        beforeEnter: (to, from, next) => {
            if (localStorage.naezip_to_ac_se) {
                next();
            }
            else {
                next("/");
            }
        },
    },
    {
        path: "/subscribe/complete",
        name: "subscribe-complete",
        component: () => import("../views/subscribe/CompleteView.vue"),
        beforeEnter: (to, from, next) => {
            if (localStorage.naezip_to_ac_se) {
                next();
            }
            else {
                next("/");
            }
        },
    },
    {
        path: "/subscribe/order",
        name: "subscribe-order",
        component: () => import("../views/subscribe/OrderView.vue"),
        beforeEnter: (to, from, next) => {
            if (localStorage.naezip_to_ac_se) {
                next();
            }
            else {
                next("/");
            }
        },
    },
    {
        path: "/subscribe/date",
        name: "subscribe-date",
        component: () => import("../views/subscribe/DateView.vue"),
        beforeEnter: (to, from, next) => {
            if (localStorage.naezip_to_ac_se) {
                next();
            }
            else {
                next("/");
            }
        },
    },
    {
        path: "/subscribe/watching",
        name: "subscribe-watching",
        component: () => import("../views/subscribe/SubscribeWatchingView.vue"),
        beforeEnter: (to, from, next) => {
            if (localStorage.naezip_to_ac_se) {
                next();
            }
            else {
                next("/");
            }
        },
    },
    {
        path: "/subscribe/credit",
        name: "subscribe-credit",
        component: () => import("../views/subscribe/CreditView.vue"),
        beforeEnter: (to, from, next) => {
            if (localStorage.naezip_to_ac_se) {
                next();
            }
            else {
                next('/');
            }
        },
    },
    {
        path: `/subscribe/history`,
        name: 'subscribe-history',
        component: () => import('../components/subscribe/History.vue'),
        beforeEnter: (to, from, next) => {
            if (localStorage.naezip_to_ac_se) {
                next();
            }
            else {
                next('/login');
            }
        },
    },
    //카드변경 링크
    {
        path: "/card-change-login",
        name: "card-change-login",
        component: () => import("../views/subscribe/payment/CardChangeLoginView.vue"),
    },
    {
        path: "/subscribe/card-change",
        name: "card-change",
        component: () => import("../views/subscribe/payment/CardChangeView.vue"),
    },
    {
        path: "/subscribe/card-change-complete",
        name: "card-change",
        component: () => import("../views/subscribe/payment/CardChangeCompleteView.vue"),
    },   

    //info
    {
        path: "/subscribe/info",
        name: "subscribe-info",
        component: () => import("../views/subscribe/info/InfoView.vue"),
    },
    {
        path: "/subscribe/terminate",
        name: "subscribe-terminate",
        component: () => import("../views/subscribe/info/Terminate.vue"),
    },   
    {
        path: "/subscribe/invite",
        name: "subscribe-invite",
        component: () => import("../views/subscribe/info/InviteView.vue"),
    },    
    {
        path: "/subscribe/invite-login",
        name: "subscribe-invite-login",
        component: () => import("../views/subscribe/info/InviteLoginView.vue"),
    },  
    {
        path: "/subscribe/invite-landing",
        name: "subscribe-invite-landing",
        component: () => import("../views/subscribe/info/InviteLandingView.vue"),
    },      
    {
        path: "/subscribe/invite-code",
        name: "subscribe-invite-code",
        component: () => import("../views/subscribe/info/InviteCodeView.vue"),
        beforeEnter: (to, from, next) => {
            if (localStorage.naezip_to_ac_se) {
                next();
            }
            else {
                next("/");
            }
        },
    },   
    
    //해지
    {
        path: "/subscribe/cancel",
        name: "subscribe-cancel",
        component: () => import("../views/subscribe//cancel/CancelView.vue"),
        beforeEnter: (to, from, next) => {
            if (localStorage.naezip_to_ac_se) {
                next();
            }
            else {
                next("/");
            }
        },
    },    
    {
        path: "/subscribe/cancel-reason",
        name: "subscribe-cancel-reason",
        component: () => import("../views/subscribe//cancel/CancelReasonView.vue"),
        beforeEnter: (to, from, next) => {
            if (localStorage.naezip_to_ac_se) {
                next();
            }
            else {
                next("/");
            }
        },
    }, 
    
    // IBK 전용 라우터(-ione)
    {
        path: "/subscribe/kakao-login-ione",
        name: "subscribe-kakao-login-ione",
        component: () => import("../views/ibk/IbkSubscribeKakaoLogin.vue"),
    },
    {
        path: "/subscribe/valid-campaign-ione",
        name: "subscribe-valid-campaign-ione",
        component: () => import("../views/ibk/IbkSubscribeValid.vue"),
    },
    {
        path: "/subscribe/address-ione",
        name: "subscribe-address-ione",
        component: () => import("../views/subscribe/SubscribeAddress.vue"),
    },
    {
        path: "/subscribe/amount-ione",
        name: "subscribe-amount-ione",
        component: () => import("../views/ibk/IbkSubscribeAmount.vue"),
    },
    {
        path: "/subscribe-ione",
        name: "subscribe-ione",
        component: () => import("../views/ibk/IbkSubscribeMainView.vue"),
    },
    {
        path: "/subscribe/choose-ione",
        name: "subscribe-choose-ione",
        component: () => import("../views/subscribe/SubscribeChoose.vue"),
    },
    {
        path: "/subscribe/search-ione",
        name: "subscribe-search-ione",
        component: () => import("../views/subscribe/SubscribeSearch.vue"),
    },
    {
        path: "/subscribe/type-ione",
        name: "subscribe-type-ione",
        component: () => import("../views/subscribe/SubscribeType.vue"),
    },
    {
        path: "/subscribe/price-ione",
        name: "subscribe-price-ione",
        component: () => import("../views/subscribe/SubscribePrice.vue"),
    },
    // {
    //     path: "/subscribe/amount-ione",
    //     name: "subscribe-amount-ione",
    //     component: () => import("../views/subscribe/SubscribeAmount.vue"),
    // },
    {
        path: "/subscribe/init-ione",
        name: "subscribe-init-ione",
        component: () => import("../views/subscribe/InitView.vue"),
        beforeEnter: (to, from, next) => {
            if (localStorage.naezip_to_ac_se) {
                next();
            }
            else {
                next("/");
            }
        },
    },
    {
        path: "/subscribe/complete-ione",
        name: "subscribe-complete-ione",
        component: () => import("../views/subscribe/CompleteView.vue"),
        beforeEnter: (to, from, next) => {
            if (localStorage.naezip_to_ac_se) {
                next();
            }
            else {
                next("/");
            }
        },
    },
    {
        path: "/subscribe/order-ione",
        name: "subscribe-order-ione",
        component: () => import("../views/subscribe/OrderView.vue"),
        beforeEnter: (to, from, next) => {
            if (localStorage.naezip_to_ac_se) {
                next();
            }
            else {
                next("/");
            }
        },
    },
    {
        path: "/subscribe/date-ione",
        name: "subscribe-date-ione",
        component: () => import("../views/subscribe/DateView.vue"),
        beforeEnter: (to, from, next) => {
            if (localStorage.naezip_to_ac_se) {
                next();
            }
            else {
                next("/");
            }
        },
    },
];


export default subscribeRouter;
